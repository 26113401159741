/* src/assets/styles/navbar.css */

/* Navbar container */
.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6c8573; /* Miami Vice light teal */
    color: #ffffff; /* White text color */
    padding: 10px;
  }
  
  /* Logo area */
  .navbar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px; /* Add space between logo and menu items */
  }
  
  /* Logo image */
  .navbar-logo img {
    height: 100px; /* Adjust the height as needed */
  }
  
  /* Navbar menu items */
  .navbar-menu {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
  }
  
  .navbar-menu li {
    margin-left: 20px; /* Add space between menu items */
  }
  
  .navbar-menu li:first-child {
    margin-left: 0; /* Remove margin for the first menu item */
  }
  
  .navbar-menu li a {
    color: #ffffff;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  /* Navbar menu items hover */
  .navbar-menu li a:hover {
    background-color: #FFD93A; /* Miami Vice pink */
  }
  
  /* Spinning animation for the logo */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Loading class to trigger the spinning animation */
  .navbar-logo.loading img {
    animation: spin 2s linear infinite;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      padding: 10px 0;
    }
  
    .navbar-logo {
      margin-right: 0; /* Reset the margin for the logo area */
      margin-bottom: 10px; /* Add some space between the logo and the menu items */
    }
  
    .navbar-menu {
      margin: 0;
    }
  
    .navbar-menu li {
      margin: 0;
      margin-bottom: 10px; /* Add space between menu items */
    }
  
    .navbar-menu li a {
      display: block;
      padding: 10px;
      text-align: center;
    }
  
    /* For responsiveness, use the same pink color on hover as in the desktop view */
    .navbar-menu li a:hover {
      background-color: #ffac25; /* Miami Vice pink */
    }
  }
  