/* src/assets/styles/footer.css */

.footer {
    background-color: #6c8573;
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-logo img {
    height: 100px;
    width: auto;
  }
  
  .footer-social {
    display: flex;
    gap: 20px;
  }
  
  .footer-social a {
    color: #fff;
    font-size: 24px;
  }
  
  .footer-text {
    color: #fff;
    text-align: center;
    margin-top: 10px;
  }
  