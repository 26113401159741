/* src/assets/styles/header.css */

.header {
    height: 500px; /* Adjust the height as needed */
    background-size: cover;
    background-position: center;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .header {
      height: 200px; /* Reduce the height for smaller screens */
    }
  }



/* src/assets/styles/work-section.css */

/* src/assets/styles/main.css */

/* ... existing styles ... */

/* New styles for the WorkSection */
.work-section {
    background-color: #6c8573; /* Light beige background */
    padding: 80px 0;
  }
  
  .work-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex; /* Use flexbox for side-by-side layout */
    justify-content: space-between; /* Distribute sections evenly */
    align-items: stretch; /* Stretch all sections to have the same height */
    flex-wrap: wrap; /* Allow flex items to wrap on smaller screens */
  }
  
  .logo {
    flex: 1; /* Let the logo take an equal portion of the available space */
    background-color: #fff; /* White background for the logo card */
    padding: 20px;
    border-radius: 10px;
    margin: 10px; /* Add some spacing between the card areas */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow effect */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo img {
    max-width: 100%;
    max-height: 200px; /* Adjust the height of the logo as needed */
    border-radius: 10px;
  }
  
  .card {
    flex: 1; /* Let each card take an equal portion of the available space */
    background-color: #fff; /* White background for all card areas */
    padding: 20px;
    border-radius: 10px;
    margin: 10px; /* Add some spacing between the card areas */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow effect */
  }
  
  .card h2 {
    font-size: 24px;
    color: #353535; /* Dark gray heading color */
    margin-bottom: 20px;
  }
  
  .card p {
    font-size: 16px;
    line-height: 1.6;
    color: #5c5c5c; /* Light gray text color */
  }
  
  .health-facts ul {
    list-style-type: square;
    padding-left: 20px;
    margin-top: 10px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .work-content {
      flex-direction: column; /* Stack the sections in a single column for small screens */
      align-items: center;
    }
  
    .logo,
    .card {
      width: 100%; /* Set the width to full for cards in the single column */
      max-width: 100%;
    }
  
    .card {
      margin: 10px 0; /* Adjust spacing between cards in the single column */
    }
  }
  
 
  

/* Styling for Events Section */

.events-section {
    padding: 50px 0;
    text-align: center;
  }
  
  .events-title {
    font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #f890e7;
    margin-bottom: 10px;
  }
  
  .events-description {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 10px;
  }
  
  .events-contact {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }
  
  .events-email {
    color: #FFD93A;
    font-weight: bold;
  }

  /* New styles for the EventSection */
.events-wrapper {
    background-color: rgba(248, 144, 231, 0.2);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .events-image {
    display: block;
    max-width: 30%;
    margin: 0 auto;
    border-radius: 10px;
  }
  
  
  