.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 800px;
    padding: 20px;
    margin: 0 auto; /* Center horizontally */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Rest of the CSS remains the same */


.logo-container {
    margin-bottom: 20px;
}

.logo-image {
    width: 150px;
    height: auto;
}

.text-container {
    line-height: 1.6;
}

.text-container h2 {
    margin-bottom: 10px;
    color: #333;
}

.text-container a {
    color: #007bff;
    text-decoration: none;
}

.text-container a:hover {
    text-decoration: underline;
}
