.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .contact-form-logo {
    flex: 1;
    text-align: center;
  }
  
  .contact-form-content {
    flex: 2;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-content h2 {
    margin-bottom: 20px;
  }
  